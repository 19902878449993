@import "../../styles/colours.scss";
@import "../../styles/media.scss";

/* MENU STYLES - mobile menu */
.menu-wrap {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1;
	display: none;
	@media ($desktop-s) {
		display: block;
		//width: 100%;
	}
	& .hamburger {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		width: 56px;
		height: 56px;
		padding: 1rem;
		background: #FFF;
		align-items: center;
		justify-content: center;
		transition: all 1s ease;
		display: none;
		cursor: pointer;
		@media ($desktop-s) {
			display: flex;
		}
		// .header--small & {
		// 	background: $dark-slate-grey;
		// }
		& > div { 							/* Hamburger Line */
			position: relative;
			flex: none;
			width: 100%;
			height: 2px;
			background: $hamburger-menu-bars;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.4s ease;
			&::before,
			&::after {			 			 /* Hamburger Lines - Top & Bottom */
				content: '';
				position: absolute;
				z-index: 1;
				top: -10px;
				width: 100%;
				height: 2px;
				background: inherit;
			}
			&::after {  					/* Moves Line Down just for after */
				top: 10px;
			}
		}
		&.hamburger-open > div {  			/* Toggler Animation */
			transform: rotate(135deg);
			&::before,		  		  		/* Turns Lines Into X */
			&::after {
				top: 0;
				transform: rotate(90deg);
			}
		}
		&.hamburger-open:hover > div {		/* Rotate On Hover When Checked */
			transform: rotate(225deg);
		}
	}
	& .menu {
		position: fixed;
		top: $header-height;
		right: 0;
		min-width: 250px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		opacity: 0;
		visibility: hidden;
		transition: visibility 0.5s ease, opacity 0.5s ease, top 1s ease;
		width: 100%; 						// required for mobile to utilise 100% via MQ in .menu-wrap .menu > div
		.header--small & {
			top: $header-height--small;
		}
		& > div {
			background: $hamburger-menu-panel;
			padding: 5px 15px;
			display: flex;
			flex: none;
			align-items: center;
			justify-content: flex-start;
			transition: all 0.4s ease;
			@media ($mobile-m) {
				width: 100%;
			}
			& > div {
				text-align: flex-start;
				max-width: 90vw;
				max-height: 100vh;
				transition: opacity 0.4s ease;
				& > ul,
				& > ul > li {
					list-style: none;
					color: #fff;
					font-size: 1.5rem;
					padding: .3rem 1rem;
					&.active {
						color: $dark-blue;
					}
				}
				& > ul > li > a {
					color: inherit;
					text-decoration: none;
					transition: color 0.4s ease;
				}
			}

		}
	}
	& .menu-open {
		opacity: 1;
		visibility: visible;
		transition: visibility 0.5s ease, opacity 0.5s ease, top 1s ease;
	}
}