@import "../../styles/colours.scss";
@import "../../styles/media.scss";



.memorial-box {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	//background: rgba(0, 110, 255, 0.05);
	width: 100%;
	& .memorial-left {
		display: flex;
		flex: 0 1 auto;
		flex-direction: column;
		align-items: flex-start;
		padding: 10px 10px 10px 0;
		//background: rgba(255, 115, 0, 0.1);
		& > * {
			margin-top: 10px;
		}
		@media ($tablet) {
			align-items: center;
			margin: 0;
		}

	}
	& .memorial-right {
		display: flex;
		flex: 2 1 auto;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 10px 0 10px 10px;
		//background: rgba(247, 0, 255, 0.1);

	}
	@media ($tablet) {
		flex-direction: column;
		margin: 0;
	}
}
.memorial-name h1 {
	//background: rgba(136, 255, 0, 0.1);
	font-size: 2.8rem;
	margin-bottom: 5px;
}
.memorial-name h2 {
	//background: rgba(136, 255, 0, 0.1);
	font-size: 1.8rem;
	margin-bottom: 5px;
}
.memorial-dates h3 {
	//background: rgba(0, 255, 170, 0.1);
	font-size: 1.4rem;
	font-weight: normal;
	margin: 2px 0 0 0;
}
P.subtle {
	color: #888888;
}

.memorial-description p {
	//background: rgba(136, 255, 0, 0.1);
	font-size: 1.4rem;
}
.memorial-image-main-box {
	display: flex;
	//flex: 1 1 auto;
	justify-content: center;
	& .memorial-image-main {
		//background: rgba(255, 0, 255, 0.1);
		max-width: 300px;
		//max-height: 400px;
	}
}
.lifetimeline-link {
	margin: 5px;
}