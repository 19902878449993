@import "../../styles/colours.scss";
@import "../../styles/media.scss";

.header {
	display: flex;
	justify-content: center;
	height: $header-height;
    transition: height 1s ease, box-shadow 1s ease, padding 1s ease, background-color 1s ease, fill 1s ease;
	padding: 10px 15px;
	position:fixed;
	width: 100%;
	background-color: #FFFFFF;
	color: #333;
	border-bottom:solid 2px $avstar-blue;
	top:0px;
	& .shadowEllipse {
		fill: #EBEBEB;
		transition: fill 1s ease;
	}
	& .mainElipse {
		fill: $avstar-blue;
		transition: fill 1s ease;
	}
	& .masthead {
		fill: $warm-black;
		transition: fill 1s ease;
	}
	& .baseLogo {
		width: 100%;
	}
	.header--small & {
		height: $header-height--small;
		padding: 6px 10px 2px 10px;
		//background-color: $slate-grey;
		//	background: rgba(71, 74, 77, 0.2);		// Used for testing

		& .shadowEllipse {
			fill: $warm-black;
			transition: fill 1s ease;
		}
		& .mainElipse {
			fill: $avstar-blue;
			transition: fill 1s ease;
		}
		& .masthead {
			fill: #FFFFFF;
			transition: fill 1s ease;
		}
		& .header-item > a,
		& .header-item > a:visited {
			color: #FFFFFF;
			text-decoration: none;
			font-size: 1.4rem;
		}
		& .header-item > a:hover {
			color: $avstar-blue;
		}
	//	&  .header-item--logo {
			//width: calc( #{$logo-w-over-h} * (#{$header-height--small} - 8px) );		// use height as measure, less padding, by ratio of width
			//height: calc( #{$header-height--small} - 8px );
			//transition: width 1s ease, height 1s ease;
	//	}
	}
}

.header--shadow {
	box-shadow: 0 13px 15px -10px rgba(0,0,0,.2);
	// USED FOR FULL-BAR NAV MENUS
	// & .nav-menu {
	// 	box-shadow: 0 13px 15px -10px rgba(0,0,0,.2);
	// }
}
.header-box {
	display: flex;
	justify-content: flex-start;
	flex: 1 1 auto;
	max-width: $page-max-width;
	align-items: center;
}
.header-item {
	display: flex;
	flex: 1 1 auto;
	height: 100%;
	//	background: rgba(255, 0, 128, 0.2);
	//	border: dashed 1px rgba(255, 0, 106, 0.9);
}
.header-item--logo {
	flex: 0 1 auto;
	margin: 0 50px 0 0;
	//width: calc( #{$logo-w-over-h} * (#{$header-height} - 22px) );		// use height as measure, less padding, by ratio of width
	//height: calc( #{$header-height} - 22px );
	//& > a {
	//	width: 100%;
	//}
	//	background: rgba(0,0,255,.2);
	//	border: dashed 1px rgba(0,0,255,.9);
	//transition: width 1s ease, height 1s ease;
}
.header-item-menu {
	justify-content: flex-end;
}





