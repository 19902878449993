.brand-box {
	display: flex;
	flex: 1 1 auto;
	justify-content: flex-start;
	height: 100%;
		//background-color: rgba(165, 165, 2, 0.05);
		//border: dashed 1px rgba(165, 165, 2, 0.9);
}

.brand-logo {
	height: 88px;
	width: 88px;
	margin-right: 10px;
	//background-color: rgba(21, 255, 0, 0.24);
	transition: width 1s ease, height 1s ease;
	.header--small & {
		height: 48px;
		width: 48px;
		transition: box-shadow 1s ease, padding 1s ease, background-color 1s ease, fill 1s ease, font-size 1s ease, width 1s ease, height 1s ease;
	}
}

.brand-masthead {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	height: 88px;
	width: 200px;
	margin-left: 20px;
	margin-top: 10px;
	transition: margin-top 1s ease;
	//background-color: rgba(255,0,255,.1);
	.header--small & {
		//flex-direction: row;
		margin-top: 0;
	}

}
.brand-masthead-inmemoriam {
	max-height: 45px;
	//margin-top: 5px;
	//width: 100%;
	height: 100%;
	//.header--small & {
		//max-height: 25px;
		//margin-top: 10px;
	//}
}
/*

brand-box
	brand-logo
	brand-masthead
		brand-masthead-avstar
		brand-masthead-solutions
*/