@import "../../styles/colours.scss";
@import "../../styles/media.scss";


/* NAV STYLES - static desktop navigation menu */
.nav-wrap {
	display: flex;
	flex: 1 1 auto;
	z-index: 2;
	display: flex;
	@media ($desktop-s) {
		display: none;
	}
	.nav-menu {
		padding: 0;
		margin-top: 20px;
		height: $nav-height;
		min-width: 250px;
		overflow: hidden;
		display: flex;
		flex: 1 1 auto;
		justify-content: center;
		transition: margin-top 1s ease, padding 1s ease, height 1s ease;
		width: 100%; 							// required for mobile to utilise 100% via MQ in .menu-wrap .menu > div
		.header--small & {
			margin-top: 7px;
			height: $nav-height--small;
			& > div > div > ul > li {
				font-size: 1.4rem;
				//padding: 50% 10px; 	=>	 don't do this. Results in janky UX
			}
			& > div {
				max-width: calc(#{$page-max-width} + 20px); 	// make wider to allow for padding (button type hover0 but still have first label left algin with logo
			}
		}
		& > div {
			display: flex;
			flex: 1 1 auto;
			align-items: center;
			transition: all 0.4s ease;
			& > div {
				display: flex;
				justify-content: flex-end;
				flex: 1 1 auto;
				transition: opacity 0.4s ease;
				& > ul {
					display: flex;
					flex: 1 1 auto;
					justify-content: flex-end;
					list-style: none;
					padding: 0;
					max-width: 600px;
				}
				& > ul > li {
					font-size: 1.4rem;
					transition: font-size 1s ease, padding 1s ease;
					padding: 10px 0;									// adjust with nav height
					display: inline;
					border-radius: 4px;
					margin-left: 10px;

					& a {
						color: inherit;
						text-decoration: none;
						padding: 20px 15px;
					}
					&:hover > a {
						color: $deep-avstar-blue;
						text-decoration: none;
						transition: color 0.4s ease;
					}
				}
				& > ul > li:hover {
					background-color: $pale-avstar-blue;
				}
				& > ul > li.active {
					background: rgba(100,100,100,.05);
				}
			}
		}
	}
}