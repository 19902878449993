@import "../../styles/colours.scss";
@import "../../styles/media.scss";


.footer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex: 1 0 auto;
	width: 100%;
	padding: 1rem;
	background-color: #555;
	min-height: 35rem;
	margin-top: 50px;
}
.footer--centre {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex: 1 1 auto;
	//-ms-flex: 0 1 auto;
	padding: 1rem;
	min-height: 35rem;
	max-width: 1330px;
	width: 100%;					// required for ie 11
	@media ($tablet) {
		flex-direction: column;
		margin: 0;
	}
}

.footer__column {
	display: flex;
	flex: 1 1 0;
	flex-direction: column;
	min-height: 15rem;
	padding:10px;
	margin: 5px 10px;
	@media ($tablet) {
		flex: 1 1 auto;
		width:100%;
		margin: 5px 0;
	}
	.brand__logo {
		margin: 0;
		height: 40px;
		// LOGO SCHEMES
		.logoScheme__ltl--slideBubble,
		.logoScheme__ltl--name,
		.logoScheme__img--elephant,
		.logoScheme__img--name,
		.logoScheme__jbc--hand,
		.logoScheme__jbc--name {
			fill: #FFF;
		}
	}
	&--subBlock {
		display: flex;
		flex-direction: row;
	}
	&--leftBlock {
		//background-color: rgba($color: #00BAFF, $alpha: 0.1);
		i {
			font-size: 6rem;
			margin: 10px 25px 0 25px;
			color: #999;
			&:hover {
				color: #FFF;
			}
		}
	}
	.footer__column--rightBlock {
		//background-color: rgba($color: #00BAFF, $alpha: 0.3);
		p {
			margin: 2px 0 0 0;
		}
	}
	H3 {
		color: #EEE;
		font-size: 1.8rem;
		margin: 10px 0 5px 0;
		font-weight: normal;
	}
}


.footer__column p,
.footer__column p a {
	color: #CCC;
	font-size: 14.4px;
	margin: 4px 0 6px 0;
	font-weight: normal;
	.footer-ask-link {
		font-size: 2.0rem;
		color: #FFF;
		text-decoration: none;
		margin-top: 10px;
	}
	.footer-ask-link:hover {
		color: #00BAFF;
		text-decoration: underline;
	}
	&.margin-bottom-20 {
		margin-bottom: 20px;
	}
	&.margin-bottom-35 {
		margin-bottom: 35px;
	}
	&.footer__socialIcons {
		display: flex;
		flex-direction: row;
		a {
			font-size: 3.6rem;
			margin-right:15px;
			padding: 1px 0 0 0;
			width: 42px;
			height: 42px;
			border-radius: 4px;
			text-align: center;
			transition: background-color 1s ease;
			&:hover .fa-facebook-official {
				color: #3b5999;
			}
			&:hover .fa-twitter-square {
				color: #55acee;
			}
			&:hover .fa-linkedin-square {
				color: #0077B5;
			}
			&:hover {
				background-color: #FFF;
			}
		}
		a:hover {
			color: #FFF;
			cursor: pointer;
		}
	}
}