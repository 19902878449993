

$lime-bright: 		rgb(180, 230, 0);
$lime-soft: 		rgb(238, 253, 181);

$pale-avstar-blue:	rgba(205, 235, 255, 0.4);
$avstar-blue: 		#4984BC;
$deep-avstar-blue: 	rgb(43, 91, 136);

$dark-lime: 		#A5BF4B;
$deep-lime: 		#828E53;

$ultra-light-grey: 	#EBEBEB;
$slate-grey: 		#474A4D;
$dark-slate-grey: 	#3A3B3D;
$warm-black: 		#181415;

$mid-blue:			rgb(0, 88, 146);
$dark-blue:			rgb(0, 64, 107);
$darker-blue:		rgb(0, 53, 90);
$deep-blue:			rgb(0, 35, 58);

$header-height:			110px;
$header-height--small:	60px;				 // zero as menu inside header for Avstar

$nav-height:			60px;
$nav-height--small:		38px;

// This set varies by nav style used. See nothes in App.scss => .main "Complex triggers of SCROLLING and PAGE-WIDTH affect main top margin"
$main-top-normal:		$header-height;
$main-top-small:		$header-height;
$main-top-mobile:		$header-height--small;

$footer-min-height:		35rem;



$logo-w-over-h:			1;
$page-max-width:		1100px;

$hamburger-menu-bars:	$avstar-blue;
$hamburger-menu-panel:	$avstar-blue;