@import "../styles/colours.scss";
@import "../styles/media.scss";

html {
	height: 100%;
	font-size: 62.5%;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	//height: 100%;
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	position: relative;
	font-size: 1.4rem;
	background: rgba(0,0,0,.05);
}

.main {
	//text-align: center;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	/*	Complex triggers of SCROLLING and PAGE-WIDTH affect main top margin

			SCREEN		  SCROLL	  HEADER	  NAV-BAR	: MAIN-HEIGHT
		1	Desktop 	: No		: Normal	: Normal	: header-height + nav-height
		2	Desktop		: Yes		: Small		: Small		: header-height + nav-height - nav-height--small
		3	Thin 		: No		: Normal	: None		: header-height
		4	Thin		: Yes		: Small		: None		: header-height + nav-height - nav-height--small
		5	Mobile		: Both		: Small		: None		: header-height--small

		1 & 4. Desktop scroll & Thin page scroll
		NOTE ONLY ADJUST BY DIFF IN NAV BAR HEIGHTS.
		We don't reduce the main by the header height as it
		would result in skip of content while scrolling.

		5. Mobile no-scroll
		Always has small menu and no nav-bar regardless of
		scrolling so always set main top margin to small header height.

	*/
	min-height: calc(100vh - #{$header-height} - #{$footer-min-height});
	margin-top: $main-top-normal;
	@media ($desktop-s) {
		margin-top: $main-top-normal;
		.header--small & {
			margin-top: $main-top-small;// main small
		}
	}
	@media ($mobile-l) {
		.header--small & {
			margin-top: $main-top-mobile; // main mobil
		}
	}
	& .section {
		width: 100%;
		max-width: 1100px;
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		//background-color: magenta;
		& p.island {
			font-size: 1.6rem;
			max-width: 480px;
			padding: 5px 20px;
			text-align: center;
			& a.text-link {
				color: #56b5eb;
				text-decoration: none;
				&:visited {
					color: #56b5eb;
				}
				&:hover {
					color: #0378bb;
				}

			}
		}
	}
}


.App-logo {
	//animation: App-logo-spin infinite 20s linear;
	height: 20vmin;
	pointer-events: none;
	margin-top: 30px;
}

.App-header {
	background-color: #FFF;
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #999;
}

.App-link {
	color: #61dafb;
}

.data {
	min-height: 200px;
	min-width: 400px;
	background-color: rgba(0,0,0,.1);
	border: dashed 1px #333;
	margin: 200px 100px 100px 100px;
 }
// @keyframes App-logo-spin {
// 	from {
// 		transform: rotate(0deg);
// 	}
// 	to {
// 		transform: rotate(360deg);
// 	}
// }


a.button-link {
	background-color:#56b5eb;
	border: solid 1px #56b5eb;
	border-radius: 4px;
	padding: 15px 30px;
	//margin: 15px 30px;
	color: #FFFFFF;
	text-decoration: none;
	font-size: 1.6rem;
	&:visited {
		color: #FFFFFF;
	}
	&:hover {
		background-color:#FFFFFF;
		color: #56b5eb;
	}
}
a.blue, a.blue:visited {
	color: #2c7faf;
	text-decoration: none;
}
a.blue:hover {
	color: #3cb6fd;
	text-decoration: underline;
}